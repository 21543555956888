/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 07.01.22
 */
import React from 'react';
import foxconnLogo from '../images/foxconn-logo.svg';

class SiteFooter extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div className={'app__footer'}>
                <img src={foxconnLogo} alt={''} />
            </div>
        )
    }
}

export default SiteFooter;
