/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 04.04.22
 */

const days = {
    'Monday': 'Monday / Pondělí',
    'Tuesday': 'Tuesday / Úterý',
    'Wednesday': 'Wednesday / Středa',
    'Thursday': 'Thursday / Čtvrtek',
    'Friday': 'Friday / Pátek',
    'Saturday': 'Saturday / Sobota',
    'Sunday': 'Sunday / Neděle',
}

export default days;
