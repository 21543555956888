/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 07.01.22
 */
import React from 'react';
import {Link} from "react-router-dom";
import localStorageService from '../localStorageService';
import {axiosApiInstance} from '../api/axiosApi';
import {getApiEndpoint} from '../api/api-helper';
import i18n from '../i18n';
import flags from '../helpers/flags';

class Languages extends React.Component {
    constructor(props) {
        super(props);

        i18n.changeLanguage('cs');

        this.state = {
            languages: [],
            flags: flags
        }
    }

    componentDidMount() {
        localStorageService.clearLanguage();

        axiosApiInstance.get(getApiEndpoint('getLanguages'))
            .then((response) => {
                if(response.status === 200) {
                    let languages = response.data.data.languages;
                    this.setState({languages: languages});
                    localStorageService.setModes(response.data.data.modes);
                    this.handleChangeDeviceModes.bind(this, response.data.data.modes)
                }
            }).catch((error) => {
            console.log(error);
        })
    }

    handleChangeLanguage(code, event) {
        this.props.changeLanguage(code);
    }

    handleChangeDeviceModes(modes) {
        this.props.changeDeviceModes(modes);
    }

    render() {
        let languages = this.state.languages;
        let flags = this.state.flags;

        return (
            <div className={'page-content'}>
                <div className={'language-selector'}>
                    {languages.map((language, i) => {
                        return <Link to={{pathname: "/"+language.code}} className={'language-btn'} onClick={this.handleChangeLanguage.bind(this, language.code)} key={i}>
                            <div className={'language-flag'}>
                                {flags[language.code] ?
                                    <img src={flags[language.code].url} alt={language.name} />
                                    :
                                    <div style={{height: '120px'}}></div>
                                }
                            </div>
                            <span>{language.name}</span>
                        </Link>
                    })}
                </div>
            </div>
        )
    }
}

export default Languages;
