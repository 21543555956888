/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 22.02.22
 */

const LocalStorageService = (function() {
    let _service;

    function _getService() {
        if(!_service) {
            _service = this;
            return _service
        }
        return _service
    }

    function _setToken(access_token) {
        localStorage.setItem('access_token', access_token);
    }

    function _setdeviceId(deviceId) {
        localStorage.setItem('device_id', deviceId);
    }

    function _setLanguage(language) {
        localStorage.setItem('lang', language);
    }

    function _setMode(mode) {
        localStorage.setItem('eboard_mode', mode);
    }

    function _setModes(modes) {
        localStorage.setItem('eboard_modes', JSON.stringify(modes));
    }

    function _setDepartments(departments) {
        localStorage.setItem('eboard_departments', JSON.stringify(departments));
    }

    function _getAccessToken() {
        return localStorage.getItem('access_token');
    }

    function _getdeviceId() {
        return localStorage.getItem('device_id');
    }

    function _getLanguage() {
        return localStorage.getItem('lang');
    }

    function _getMode() {
        return localStorage.getItem('eboard_mode');
    }

    function _getModes() {
        return JSON.parse(localStorage.getItem('eboard_modes'));
    }

    function _getDepartments() {
        return JSON.parse(localStorage.getItem('eboard_departments'));
    }

    function _clearToken() {
        localStorage.removeItem('access_token');
    }

    function _clearLanguage() {
        localStorage.removeItem('lang');
    }

    return {
        getService : _getService,
        setToken : _setToken,
        getAccessToken : _getAccessToken,
        clearToken : _clearToken,
        setdeviceId : _setdeviceId,
        getdeviceId : _getdeviceId,
        setLanguage : _setLanguage,
        getLanguage : _getLanguage,
        setMode : _setMode,
        getMode : _getMode,
        setModes : _setModes,
        getModes : _getModes,
        setDepartments : _setDepartments,
        getDepartments : _getDepartments,
        clearLanguage : _clearLanguage
    }
})();

export default LocalStorageService;
