/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 26.01.22
 */

import LocalStorageService from '../localStorageService';
const axios = require('axios');

const localStorageService = LocalStorageService.getService();

const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.request.use(
    config => {
        const token = localStorageService.getAccessToken();
        if(token) {
            config.headers['access-token'] = token;
        }
        return config;
    }
)

axiosApiInstance.interceptors.response.use((response) => {
    return response;
}, function(error) {
    // const originalRequest = error.config;
    // if(error.response.status === 401) {
    //access_token
    //     originalRequest._retry = true;
    //
    //     return axios.post('')
    //         .then(res => {
    //             if(res.status === 201) {
    //                 localStorageService.setToken(res);
    //
    //                 axios.defaults.headers.common['access-token'] = localStorageService.getAccessToken();
    //
    //                 return axios(originalRequest);
    //             }
    //         })
    // }
    console.log(error);
})

export {axiosApiInstance};



