/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 09.12.21
 */
import React from 'react';
import {Link} from "react-router-dom";
import defaultIco from '../images/ico-v2.png';
import arrow from '../images/arrow.svg';
import {axiosApiInstance} from '../api/axiosApi';
import {getApiEndpoint} from '../api/api-helper';
import localStorageService from '../localStorageService';
import i18n from '../i18n.js';
import i18next from 'i18next';

class CategoriesPage extends React.Component {
    constructor(props) {
        super(props);

        localStorageService.setLanguage(props.match.params.lang);
        i18n.changeLanguage(props.match.params.lang);

        this.state = {
            activeLang: localStorageService.getLanguage(),
            categories: [],
            isLoaded: false,
            deviceModes: localStorageService.getModes()
        };
    }

    componentDidMount() {
        axiosApiInstance.get(getApiEndpoint('getCategories', {lang: this.props.match.params.lang}))
            .then((response) => {
                if(response.status === 200) {
                    let categories = this.state.categories;
                    if(response.data.data) {
                        categories = Object.values(response.data.data.categories);
                        localStorageService.setModes(response.data.data.modes);
                    }
                    this.setState({categories: categories, deviceModes: response.data.data.modes, isLoaded: true});
                }
            }).catch((error) => {
                console.log(error);
        })
    }

    render() {
        let {categories, activeLang, isLoaded, deviceModes} = this.state;

        if(isLoaded) {
            if(categories.length > 0 || deviceModes.includes('shifts')) {
                return(
                    <div className={'page-content'}>
                        <div className={'categories-list'}>
                            {categories.map(function(category, i){
                                let link = '/'+activeLang+'/'+category.slug;
                                let icon = category.icon_url ?? defaultIco;

                                return <Link to={{pathname: link}} className={'category-item'} key={i}>
                                    <div className={'category-item__icon'}>
                                        <img src={icon} alt={category.title} />
                                    </div>
                                    <div className={'category-item__content'}>
                                        <span className={'category-item__title'}>{category.name}</span>
                                        <img src={arrow} className={'category-item__arrow'} alt={''} />
                                    </div>
                                </Link>
                            })}


                            {deviceModes.includes('shifts') &&
                                <Link to={{pathname: '/'+activeLang+'/departments'}} className={'category-item'} key={'shifts'}>
                                    <div className={'category-item__icon'}>
                                        <img src={defaultIco} alt='Směny' />
                                    </div>
                                    <div className={'category-item__content'}>
                                        <span className={'category-item__title'}>{i18next.t('Shift schedule')}</span>
                                        <img src={arrow} className={'category-item__arrow'} alt={''} />
                                    </div>
                                </Link>
                            }
                        </div>
                    </div>
                )
            }else{
                return <div className={'empty-section'}>
                    <div className={'empty-section__content'}>
                        <p>{i18next.t('Momentálně není k dispozici žádný obsah.')}</p>
                        <Link to={{pathname: '/'}}>{i18next.t('Výběr jazyka')}</Link>
                    </div>
                </div>;
            }
        } else {
            return <></>;
        }
    }
}

export default CategoriesPage;
