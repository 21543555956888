/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 01.03.22
 */

export default {
    apiBaseUrl: 'https://foxportal.cz.foxconn.com/wp-json/eboard/api', // prod
    // apiBaseUrl: 'http://foxportal.m31dev.cz/wp-json/eboard/api', // dev
    endpoint: {
        getLanguages: '/languages',
        getCategories: '/{lang}/categories',
        getCategoryPosts: '/{lang}/categories/{category}/posters',
        getScreensaverSlides: '/screen-savers',
        login: '/authorization/login',
        getDepartments: '/shift-calendar/departments',
        getDepartmentShifts: '/shift-calendar/departments/{department_id}/shifts/{from}/{to}'
    }
}
