/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 20.12.21
 */
import React from 'react';
import {Link} from 'react-router-dom';
import localStorageService from '../localStorageService';
import i18next from 'i18next';
import { withRouter } from "react-router";
import {axiosApiInstance} from '../api/axiosApi';
import {getApiEndpoint} from '../api/api-helper';
import flags from '../helpers/flags';

class SiteHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeLang: localStorageService.getLanguage(),
            categories: [],
            isLoaded: false,
            key: Math.random(),
            modes: localStorageService.getModes()
        }
    }

    componentDidMount() {
        if(this.state.activeLang && this.state.modes.includes('info')) {
            axiosApiInstance.get(getApiEndpoint('getCategories', {lang: this.state.activeLang}))
                .then((response) => {
                    if(response.status === 200) {
                        let categories = [];
                        if(response.data.data) {
                            categories = Object.values(response.data.data.categories) ?? [];
                        }
                        this.setState({categories: categories, deviceModes: response.data.data.modes, isLoaded: true});
                    }
                }).catch((error) => {
                console.log(error);
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let lang = localStorageService.getLanguage();

        if(prevState.activeLang !== lang && lang !== null && this.state.modes.includes('info')) {
            axiosApiInstance.get(getApiEndpoint('getCategories', {lang: lang}))
                .then((response) => {
                    if(response.status === 200) {
                        let categories = [];
                        if(response.data.data) {
                            categories = Object.values(response.data.data.categories) ?? [];
                        }
                        this.setState({activeLang: lang, categories: categories});
                    }
                }).catch((error) => {
                console.log(error);
            })
        }else if(prevState.activeLang !== null && lang === null && !this.state.modes.includes('info')){
            this.setState({activeLang: null, categories: []});
        }
    }

    handleHome() {
        this.setState({
            activeLang: null,
            categories: [],
            key: Math.random(),
            modes: localStorageService.getModes()
        })
    }

    handleBack() {
        this.props.history.goBack();
    }

    handleCancel(event) {
        event.preventDefault();
        this.props.showScreenSaver(true);
    }

    render() {
        let {categories, activeLang} = this.state;
        activeLang = localStorageService.getLanguage();
        let modes = localStorageService.getModes();

        if(!modes) {
            return (<></>);
        }

        let departmentsUrl = '/';
        if(modes.includes('info') && modes.includes('shifts') && activeLang !== null) {
                departmentsUrl = '/'+activeLang+'/departments';
        }

        return (
            <header className="app__header">
                <nav className="navbar navbar-expand navbar-dark p-0">
                    <div className="container-fluid p-0 d-flex align-items-center justify-content-between">
                        <div className={'back-btn-wrapper'} style={{width: '100px'}}>
                            {this.props.history.location.pathname !== '/' &&
                                <span className="navbar-brand bck-btn" onClick={this.handleBack.bind(this)}></span>
                            }
                        </div>
                        <Link to={'/'} onClick={this.handleHome.bind(this)} className={'app__header-title'}>eBoard</Link>
                        <ul className="navbar-nav">
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown"
                                   role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="navbar-toggler-icon"></span>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                    {categories.length > 0 &&
                                        <li>
                                            <Link to={{pathname: '/'+activeLang}} className="dropdown-item">{i18next.t('Hlavní nabídka')}</Link>
                                            <ul className="submenu">
                                                {categories.map(function(category, i){
                                                    let link = '/'+activeLang+'/'+category.slug;
                                                    return <li key={i}>
                                                        <Link to={{pathname: link}} className="dropdown-item">{category.name}</Link>
                                                    </li>
                                                })}
                                            </ul>
                                        </li>
                                    }
                                    {(this.state.activeLang !== null && modes.includes('info')) &&
                                        <li>
                                            <Link className="dropdown-item" to={{pathname: '/'}}>
                                                {i18next.t('Výběr jazyka')}
                                                {flags[this.state.activeLang] &&
                                                    <img src={flags[this.state.activeLang].url} alt={this.state.activeLang} style={{height: '35px', marginLeft: '20px'}} />
                                                }
                                            </Link>
                                        </li>
                                    }
                                    {((modes.includes('shifts') && modes.length === 1)) &&
                                        <li><Link className="dropdown-item" to={{pathname: departmentsUrl}}>{i18next.t('Provozy / Production HALL')}</Link></li>
                                    }
                                    {((modes.includes('shifts') && modes.includes('info') && activeLang)) &&
                                        <li><Link className="dropdown-item" to={{pathname: departmentsUrl}}>{i18next.t('Shift schedule')}</Link></li>
                                    }
                                    <li><a className="dropdown-item" href="#" onClick={this.handleCancel.bind(this)}>{i18next.t('Konec')} {(modes.includes('shifts') && modes.length === 1) && <span>/ {i18next.t('End')}</span> }</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        )
    }
}

export default withRouter(SiteHeader);
