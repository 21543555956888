import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import csTranslations from './i18n/locales/cs.json';
import enTranslations from './i18n/locales/en.json';
import plTranslations from './i18n/locales/pl.json';
import bgTranslations from './i18n/locales/bg.json';
import ruTranslations from './i18n/locales/ru.json';
import ukTranslations from './i18n/locales/uk.json';
import viTranslations from './i18n/locales/vi.json';
import mnTranslations from './i18n/locales/mn.json';
import roTranslations from './i18n/locales/ro.json';
import localStorageService from './localStorageService';

const resources = {
    cs: {translation: csTranslations},
    en: {translation: enTranslations},
    pl: {translation: plTranslations},
    bg: {translation: bgTranslations},
    ru: {translation: ruTranslations},
    uk: {translation: ukTranslations},
    vi: {translation: viTranslations},
    mn: {translation: mnTranslations},
    ro: {translation: roTranslations},
};

const I18N_LANGUAGE = localStorageService.getLanguage();

i18next
// load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
// learn more: https://github.com/i18next/i18next-xhr-backend
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        fallbackLng: I18N_LANGUAGE ? I18N_LANGUAGE : 'cs',
        debug: false,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18next;
