/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 01.03.22
 */

import apiConfig from './config';

/**
 * @param {string} endpoint_name
 * @param {Object} endpoint_parts
 * @returns {string}
 */
export function getApiEndpoint(endpoint_name, endpoint_parts = {}) {
    const baseUrl = apiConfig.apiBaseUrl;
    let endpoint = apiConfig.endpoint[endpoint_name];

    for (let [key, value] of Object.entries(endpoint_parts)) {
        let endpoint_key = '{'+key+'}';
        endpoint = endpoint.replace(endpoint_key, value);
    }

    return baseUrl + endpoint;
}
