/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 09.12.21
 */
import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import ReactPlayer from 'react-player'
import ReactPlayer from 'react-player/lazy'
import {axiosApiInstance} from '../api/axiosApi';
import {getApiEndpoint} from '../api/api-helper';
import { withRouter } from "react-router";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import localStorageService from '../localStorageService';
import db from '../db';
import { liveQuery } from "dexie";

const observable = liveQuery (
    () => db.videos.toArray() // A promise-returning function that queries Dexie.
);

class ScreenSaverPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            interval: 10000,
            currentSlide: 0,
            slides: [],
            slides_orig: [],
            swiper: null,
            key: Math.random(),
            isLoaded: true,
            timer: false,
            videos: [],
            isDownloading: false
        };
    }

    componentDidMount() {
        this.subscription = observable.subscribe(
            result => this.setState({videos: result})
        );

        axiosApiInstance.get(getApiEndpoint('getScreensaverSlides'))
            .then((response) => {
                if(response.status === 200) {
                    let slides = response.data.data.screen_savers;

                    if(slides.length > 0) {
                        slides.forEach((slide, index) => {
                            let fileExt = this.getExt(slide.file_url);
                            let fileType = this.getFileType(fileExt);
                            let fileUrl = this.getBlobUrl(slide.file_url);

                            slides[index] = {
                                ...slide,
                                playing: false,
                                file_type: fileType
                            }
                        })
                    }

                    this.setState({slides: slides, slides_orig: slides, isLoaded: true});
                }
            }).catch((error) => {
            console.log(error);
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.key !== this.state.key) {
            this.setState({slides: this.state.slides_orig, isLoaded: true});
        }
    }

    componentWillUnmount() {
        clearTimeout(this.state.timer);
        if(this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    getExt(filepath) {
        return filepath.split("?")[0].split("#")[0].split('.').pop();
    }

    getFileType(fileExt) {
        let fileType;
        fileExt = fileExt.toLowerCase();

        switch (fileExt) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'webp':
                fileType = 'image';
                break;
            case 'mp4':
                fileType = 'video';
                break;
            default:
                fileType = null;
                break;
        }

        return fileType;
    }

    getBlobUrl(fileUrl) {
        let videosInDB = this.state.videos;
        let blobUrl = '';
        let blobExists = false;

        blobUrl = videosInDB.map((video) => {
            if(video.url === fileUrl) {
                blobExists = true;
                return URL.createObjectURL(video.blob);
            }
        })

        if(blobExists) {
            return blobUrl[0];
        }else{
            this.downloadVideo(fileUrl);
            return fileUrl;
        }
    }

    downloadVideo(fileUrl) {
        var xhr = new XMLHttpRequest(),
            blob;

        xhr.open("GET", fileUrl, true);
        // Set the responseType to blob
        xhr.responseType = "blob";

        xhr.addEventListener("load", function () {
            if (xhr.status === 200) {
                // Blob as response
                blob = xhr.response;

                // Put the received blob into IndexedDB
                // t.storeInDB(db, blob);
                let id = db.videos.add({url: fileUrl, blob});
            }
        }, false);
        // Send XHR
        xhr.send();
    }

    videoEnded = () => {
        let swiper = this.state.swiper;
        let currentSlide = swiper.realIndex;
        let slides = this.state.slides;
        let slide = slides[currentSlide];

        slide.playing = false;
        slides[currentSlide] = slide;

        this.setState({slides: slides});

        if(slides.length === swiper.realIndex + 1) {
            this.setState({key: Math.random(), isLoaded: false});
        }else{
            swiper.slideNext(500);
        }
    }

    handleError = () => {
        this.videoEnded();
    }

    handleLoopError = (error, data) => {
        // this.setState({key: Math.random(), isLoaded: false});
    }

    updateSwiperCurrentSlide = (swiper) => {
        let index = swiper.realIndex;
        let slides = this.state.slides;
        let currentSlide = this.state.slides[index];
        clearTimeout(this.state.timer);

        if(currentSlide.file_type === 'video') {
            let slide = slides[index];
            slide.playing = true;
            slides[index] = slide;
            this.setState({slides: slides});
        }else{
            if(index + 1 === slides.length) {
                let timer = setTimeout(()=> {
                    this.setState({key: Math.random(), isLoaded: false});
                }, this.state.interval);
                this.setState({timer: timer});
            }else{
                let timer = setTimeout(()=> {
                    swiper.slideNext(500);
                }, this.state.interval);
                this.setState({timer: timer});
            }
        }

        this.setState({swiper: swiper});
    }

    endScreensaver = () => {
        localStorageService.clearLanguage();
        this.props.history.push('/');
    }

    render() {
        let {slides, isLoaded, videos} = this.state;

        if(slides.length > 1 && isLoaded) {
            return (
                <div className={'screensaver-wrapper'} key={this.state.key}>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        pagination={false}
                        onSnapIndexChange={this.updateSwiperCurrentSlide}
                        allowTouchMove={false}
                        onClick={this.endScreensaver}
                        onTouchEnd={this.endScreensaver}
                    >
                        {this.state.slides.map((slide,index) => {
                            let slideId = 'slide-'+index;
                            if(slide.file_type === 'video') {
                                let videoUrl = slide.file_url;
                                let blobExists = false;
                                let blobUrl = '';

                                let blob = videos.map((video) => {
                                    if(video.url === videoUrl) {
                                        blobExists = true;
                                        blobUrl = URL.createObjectURL(video.blob);
                                        return true;
                                    }
                                });

                                return <SwiperSlide key={slideId}>
                                    <ReactPlayer slide={this.state.currentSlide} url={blobExists ? blobUrl : videoUrl} onEnded={this.videoEnded} onError={this.handleError} loop={false} playing={slide.playing} muted={true} width={'100%'} height={'100%'} />
                                </SwiperSlide>
                            }else if(slide.file_type === 'image'){
                                return <SwiperSlide key={slideId}>
                                    <img src={slide.file_url} alt={''} />
                                </SwiperSlide>
                            }else{
                                return <></>;
                            }
                        })}
                    </Swiper>
                </div>
            )
        }else if(slides.length === 1 && isLoaded){
            return (
                <div className={'screensaver-wrapper single-screensaver'} key={this.state.key}>
                    {this.state.slides.map((slide,index) => {
                        if(slide.file_type === 'video') {
                            return <ReactPlayer slide={this.state.currentSlide} url={slide.file_url} onError={this.handleLoopError} playing={true} loop={true} muted={true} width={'100%'} height={'100%'} key={index} />
                            // return <ReactPlayer slide={this.state.currentSlide} url='https://www.youtube.com/watch?v=MkL3TjwGnZg' onError={this.handleLoopError} playing={true} loop={true} muted={true} width={'100%'} height={'100%'} key={index} />
                        }else if(slide.file_type === 'image'){
                            return <img src={slide.file_url} alt={''} key={index} />
                        }else{
                            return <></>;
                        }
                    })}
                </div>
            )
        }else{
            return(<></>);
        }
    }
}

export default withRouter(ScreenSaverPage);
