/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 28.03.23
 */

import Dexie from 'dexie';

const db = new Dexie('foxkios');
db.version(1).stores({
    videos: '++id, url, blob', // Primary key and indexed props
});

export default db;
