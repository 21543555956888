/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 30.03.22
 */

import React from 'react';
import departmentImg from '../images/department.png';
import {Link} from 'react-router-dom';
import i18next from 'i18next';
import {axiosApiInstance} from '../api/axiosApi';
import {getApiEndpoint} from '../api/api-helper';
import localStorageService from '../localStorageService';
import i18n from '../i18n';

class DepartmentsPage extends React.Component {
    constructor(props) {
        super(props);

        localStorageService.setLanguage(props.match.params.lang);
        i18n.changeLanguage(props.match.params.lang);

        this.state = {
            departments: [],
            activeLang: localStorageService.getLanguage(),
            isLoaded: false
        };
    }

    componentDidMount() {
        axiosApiInstance.get(getApiEndpoint('getDepartments'))
            .then((response) => {
                if(response.status === 200) {
                    let departments = response.data.data.departments;
                    this.setState({departments: departments, isLoaded: true});
                    localStorageService.setDepartments(departments);
                    localStorageService.setModes(response.data.data.modes);
                    this.handleChangeDeviceModes.bind(this, response.data.data.modes)
                }
            }).catch((error) => {
            console.log(error);
        })
    }

    handleChangeDeviceModes(modes) {
        this.props.changeDeviceModes(modes);
    }

    render() {
        let {departments, isLoaded, activeLang} = this.state;
        let modes = localStorageService.getModes();

        if(isLoaded) {
            if(departments.length > 0) {
                return(
                    <div className={'page-content'}>
                        <p className={'departments-subheadline'} style={{height: '96px'}}></p>
                        <div className={'departments-list'}>
                            {departments.map(function(department, i){
                                let link = '/'+department.id;
                                if(modes.includes('info')) {
                                    link = '/'+activeLang+'/departments'+link;
                                }
                                let image = departmentImg;
                                if(department.image_url && department.image_url !== '') {
                                    image = department.image_url;
                                }

                                return <Link to={{pathname: link}} className={'department-item'} key={i}>
                                    <div className={'department-item__image'}>
                                        <img src={image} alt={department.title} />
                                    </div>
                                    <div className={'department-item__content'}>
                                        <span className={'department-item__title'}>{department.title}</span>
                                    </div>
                                </Link>
                            })}
                        </div>
                    </div>
                )
            }else{
                return <div className={'empty-section'}>
                    <div className={'empty-section__content'}>
                        <p>{i18next.t('Momentálně není k dispozici žádný obsah.')}</p>
                        <Link to={{pathname: '/'}}>{i18next.t('Výběr jazyka')}</Link>
                    </div>
                </div>;
            }
        } else {
            return <div className={'empty-section'}>
                <div className={'empty-section__content'}>
                    <p>{i18next.t('Načítám...')}</p>
                </div>
            </div>;
        }
    }
}

export default DepartmentsPage;
