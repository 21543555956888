import React, {Fragment} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min'
import ScreenSaverPage from './pages/Screensaver';
import Languages from './pages/Languages';
import CategoriesPage from './pages/Categories';
import SchedulesPage from './pages/Schedules';
import SiteHeader from './components/SiteHeader';
import SiteFooter from './components/SiteFooter';
import PostsPage from './pages/Posts';
import localStorageService from './localStorageService';
import {axiosApiInstance} from './api/axiosApi';
import {getApiEndpoint} from './api/api-helper';
import DepartmentsPage from './pages/Departments';

const allCapsAlpha = [..."ABCDEFGHIJKLMNOPQRSTUVWXYZ"];
const allNumbers = [..."0123456789"];
const base = [...allCapsAlpha, ...allNumbers];


class App extends React.Component {
    constructor(props) {
        super(props);

        let timeoutMinutes = 2; // minutes
        const query = new URLSearchParams(window.location.search);
        let mode = query.get('mode');
        if(mode) {
            localStorageService.setMode(mode);
        }

        // localStorageService.setModes(['info']);

        this.state = {
            timeout: 1000 * 60 * timeoutMinutes, // prod
            // timeout: 1000 * 60 * 20, // dev
            showScreenSaver: false,
            userLoggedIn: false,
            isTimedOut: false,
            canceled: false,
            deviceId: localStorageService.getdeviceId() ?? null,
            eboardMode: localStorageService.getMode() ?? null,
            eboardModes: localStorageService.getModes() ?? [],
            authorized: false,
            lang: localStorageService.getLanguage() ?? 'cs'
        }

        if(this.state.eboardModes.length < 1) {
            axiosApiInstance.get(getApiEndpoint('getLanguages'))
                .then((response) => {
                    if(response.status === 200) {
                        localStorageService.setModes(response.data.data.modes);
                        this.state.eboardModes = response.data.data.modes;
                        window.location.reload(false);
                    }
                }).catch((error) => {
                console.log(error);
            })
        }

        // if(localStorageService.getMode() === 'shifts') {
        //     timeoutMinutes = 1;
        // }

        this.idleTimer = null;
        this.onAction = this._onAction.bind(this);
        this.onActive = this._onActive.bind(this);
        this.onIdle = this._onIdle.bind(this);

        if (localStorage.getItem('I18N_LANGUAGE') === null) {
            localStorage.setItem('I18N_LANGUAGE', 'cs');
        }
    }

    componentDidMount() {
        let deviceId = localStorageService.getdeviceId();
        let accessToken = localStorageService.getAccessToken();

        if (deviceId === null) {
            deviceId = this.generator(base, 6);
            localStorageService.setdeviceId(deviceId);
            this.setState({deviceId: deviceId});
            this.authorizeApp(deviceId);
        } else if (accessToken === null) {
            this.authorizeApp(deviceId);
        }

        if(deviceId && accessToken) {
            this.setState({authorized: true});
        }

        axiosApiInstance.get(getApiEndpoint('getLanguages'))
            .then((response) => {
                if(response.status === 200) {
                    localStorageService.setModes(response.data.data.modes);
                    this.setState({eboardModes: response.data.data.modes});
                }
            }).catch((error) => {
            console.log(error);
        })
    }

    authorizeApp(deviceId) {
        axiosApiInstance.post(getApiEndpoint('login'), {device_id: deviceId})
            .then((response) => {
                if(response.status === 200) {
                    let accessToken = response.data.data.access_token;
                    localStorageService.setToken(accessToken);
                    this.setState({authorized: true});
                }
            }).catch((error) => {
            console.log(error);
        })

        // OK
        // let accessToken = this.generator(base, 12);
        // localStorageService.setToken(accessToken);
        // this.setState({authorized: true});
    }

    generator = (base, len) => {
        return [...Array(len)]
            .map(i => base[Math.random()*base.length|0])
            .join('');
    };

    _onAction(e) {
        if(!this.state.canceled) {
            this.setState({isTimedOut: false, showScreenSaver: false});
        }else{
            this.setState({canceled: false});
        }
    }

    _onActive(e) {
        this.setState({isTimedOut: false})
    }

    _onIdle(e) {
        const isTimedOut = this.state.isTimedOut
        if (isTimedOut) {
            // this.props.history.push('/')
        } else {
            this.setState({showScreenSaver: true})
            this.idleTimer.reset();
            this.setState({isTimedOut: true})
            localStorage.removeItem('lang');
        }
    }

    changeLanguage(lang) {
        localStorageService.setLanguage(lang);
        this.setState({lang: lang});
    }

    changeDeviceModes(modes) {
        localStorageService.setModes(modes);
        this.setState({eboardModes: modes});
    }

    showScreenSaver(show) {
        if(show) {
            this.setState({canceled: true, showScreenSaver: true})
            this.idleTimer.reset();
            this.setState({isTimedOut: true});
            localStorage.removeItem('lang');
        }
    }

    render() {
        let {authorized, deviceId, eboardModes} = this.state;
        // let accessToken = localStorageService.getAccessToken();

        let appClass = 'app';
        let contentClass = 'app__content';
        // if(eboardMode === 'shifts') {
        if(eboardModes.includes('shifts')) {
            appClass += ' app--shifts';
            contentClass += ' app__content--shifts';
        }

        return (<>
            <IdleTimer
                ref={ref => {
                    this.idleTimer = ref
                }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                onAction={this.onAction}
                debounce={250}
                timeout={this.state.timeout}/>
            <BrowserRouter basename={`/`}>
                <div className={appClass}>
                    <SiteHeader showScreenSaver={this.showScreenSaver.bind(this)} />
                    <div className={contentClass}>
                        {(authorized && eboardModes.includes('info')) &&
                            <>
                                {eboardModes.includes('shifts') ?
                                    <Switch>
                                        {/*Languages*/}
                                        <Route path="/" exact>
                                            <Languages changeLanguage={this.changeLanguage.bind(this)} changeDeviceModes={this.changeDeviceModes.bind(this)} />
                                        </Route>

                                        {/*Departments list*/}
                                        <Route path={'/:lang/departments'} component={DepartmentsPage} exact />

                                        {/*Department schedule*/}
                                        <Route path={'/:lang/departments/:department'} component={SchedulesPage} exact />

                                        {/*Categories list*/}
                                        <Route path="/:lang" component={CategoriesPage} exact />

                                        {/*Posts of category*/}
                                        <Route path="/:lang/:category" component={PostsPage} exact />
                                    </Switch>
                                    :
                                    <Switch>
                                        {/*Languages*/}
                                        <Route path="/" exact>
                                            <Languages changeLanguage={this.changeLanguage.bind(this)} changeDeviceModes={this.changeDeviceModes.bind(this)} />
                                        </Route>
                                        {/*Categories list*/}
                                        <Route path="/:lang" component={CategoriesPage} exact />
                                        {/*Posts of category*/}
                                        <Route path="/:lang/:category" component={PostsPage} exact />
                                    </Switch>
                                }
                            </>
                        }

                        {(authorized && eboardModes.length === 1 && eboardModes.includes('shifts')) &&
                            <Switch>
                                {/*Departments list*/}
                                <Route path={'/'} component={DepartmentsPage} exact />

                                {/*Department schedule*/}
                                <Route path={'/:department'} component={SchedulesPage} exact />
                            </Switch>
                        }
                        {!authorized &&
                            <div className={"unauthorized"}>
                                <div className={"unauthorized__content"}>
                                    <p>Tento kiosek není autorizovaný. Zkuste to později.</p>
                                    <p>Kód pro autorizaci</p>
                                    <p className={"code"}>{deviceId}</p>
                                    <button type={"button"} onClick={this.authorizeApp.bind(this, localStorageService.getdeviceId())}>Zkusit znovu</button>
                                </div>
                            </div>
                        }
                    </div>

                    {this.state.showScreenSaver &&
                        <ScreenSaverPage />
                    }
                    <SiteFooter />
                </div>
            </BrowserRouter>
        </>);
    }
}

export default App;
