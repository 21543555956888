/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 30.03.22
 */

import React from 'react';
import icoSun from '../images/icon-sun.png';
import icoAfternoon from '../images/icon-afternoon.png';
import icoMoon from '../images/icon-moon.png';
import moment from 'moment';
import colors from '../helpers/colors';
import days from '../helpers/days';
import {axiosApiInstance} from '../api/axiosApi';
import {getApiEndpoint} from '../api/api-helper';
import i18next from 'i18next';
import localStorageService from '../localStorageService';
import {useParams} from 'react-router';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class SchedulesPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            department: {},
            departments: localStorageService.getDepartments(),
            schedules: {
                actualWeek: {},
                nextWeek: {}
            },
            groups: {},
            dates: {
                year: moment().year(),
                actualWeek: moment().isoWeek(),
                nextWeek: moment().add(1, 'weeks').isoWeek()
            },
            isLoaded: false,
            isBalancePeriodVisible: false
        };
    }

    async componentDidMount() {
        await this.prepareWeeks();

        axiosApiInstance.get(getApiEndpoint('getDepartmentShifts', {
            department_id: this.props.match.params.department,
            from: moment().startOf('isoWeek').format('X'),
            to: moment().add(1, 'weeks').endOf('isoWeek').add(1, 'days').format('X')
        }))
            .then((response) => {
                if(response.status === 200) {
                    this.prepareSchedules(response.data.data.shifts);
                }
            }).catch((error) => {
            console.log(error);
        })
    }

    prepareWeeks() {
        let {actualWeek, nextWeek} = this.state.dates;
        let actualWeekDays = this.getWeekDaysByWeekNumber('actualWeek', actualWeek);
        let nextWeekDays = this.getWeekDaysByWeekNumber('nextWeek', nextWeek);

        let schedules = {
            actualWeek: actualWeekDays,
            nextWeek: nextWeekDays
        };

        this.setState({schedules});
    }

    prepareSchedules(shift_schedules) {
        let {schedules, dates} = this.state;
        let groups = {};

        // let timeFix = 2*60*60; // remove 2 hours from timestamp

        shift_schedules.map((shift_schedule, i) => {
            // let shiftDate = moment.unix(shift_schedule.date_start - timeFix);
            let shiftDate = moment.unix(shift_schedule.date_start).utc();
            let dateId = parseInt(shiftDate.format('YYYYMMDD'));
            let shiftWeek = shiftDate.isoWeek();

            // let shiftStart = moment.unix(shift_schedule.date_start - timeFix).format('H:mm');
            let shiftStart = moment.unix(shift_schedule.date_start).utc().format('H:mm');
            // let shiftEnd = moment.unix(shift_schedule.end_date - timeFix).format('H:mm');
            let shiftEnd = moment.unix(shift_schedule.end_date).utc().format('H:mm');
            shift_schedule.startPosition = 's'+shiftStart.replace(':','_');
            if(shiftEnd === '6:00') {
                shift_schedule.endPosition = 'end';
            }else{
                shift_schedule.endPosition = 's'+shiftEnd.replace(':','_');
            }
            shift_schedule.time = shiftStart+' - '+shiftEnd;

            if(shiftWeek === dates.actualWeek) {
                if(schedules.actualWeek[dateId]) {
                    schedules.actualWeek[dateId].shifts.push(shift_schedule);
                }
            }else{
                if(schedules.nextWeek[dateId]) {
                    schedules.nextWeek[dateId].shifts.push(shift_schedule);
                }
            }

            groups[shift_schedule.group.title] = {
                title: shift_schedule.group.title,
                color: shift_schedule.group.color
            };
        })

        this.setState({schedules, groups, isLoaded: true});
    }

    getWeekDaysByWeekNumber(weekType, weeknumber)
    {
        let date = moment().isoWeek(weeknumber||1).startOf("isoWeek");
        if(weekType === 'nextWeek' && weeknumber === 1) {
            date = moment().add(1, 'weeks').isoWeek(weeknumber||1).startOf("isoWeek");
        }
        let weeklength=7, result = {};
        while(weeklength--)
        {
            let dayId = parseInt(date.format('YYYYMMDD'));
            result[dayId] = {
                date: date.format('D.M.'),
                day: days[date.format('dddd')],
                shifts: []
            };
            date.add(1,"day")
        }
        return result;
    }

    render() {
        let {isLoaded, schedules, dates, departments, groups} = this.state;

        let nextWeekYear = dates.year;
        if(dates.nextWeek === 1) {
            nextWeekYear += 1;
        }

        let department_title = '';
        let balance_period = '';
        let department_info = {
            title: '',
            balance_period: ''
        }
        let department_id = this.props.match.params.department;
        departments.map(function(department_item, i) {
            if(department_item.id === department_id) {
                department_title = department_item.title;
                balance_period = department_item.balance_period;
            }

            department_info.title = department_title;
            department_info.balance_period = balance_period;

            return department_info;
        });

        if(isLoaded) {
            return (
                <div className={'page-content'}>
                    <div className={'schedule-department-headline'}>
                        <span>{department_info.title}</span>
                    </div>
                    <div className={'schedule-tables'}>
                        <div className={'schedule-table'}>
                            <div className={'schedule-table__header'}>
                                <div className={'schedule-table__header-week'}>
                                    <span className={'week-label'}>Aktuální týden</span>
                                    <span className={'week-info'}>{dates.year} / {dates.actualWeek}. týden</span>
                                </div>
                                <div className={'schedule-table__header-columns'}>
                                    <div className={'column-date'}><span>Date / datum</span></div>
                                    <div className={'column-day'}><span>Day / den</span></div>
                                    <div className={'column-times'}>
                                        <div className={'column-times__parts'}>
                                            <div className={'column-times__part'}>
                                                <img src={icoSun} alt={'Morning / Ráno'} /> <span>Morning / Ráno</span>
                                            </div>
                                            <div className={'column-times__part'}>
                                                <img src={icoAfternoon} alt={'Afternoon / Odpoledne'} /> <span>Afternoon / Odpoledne</span>
                                            </div>
                                            <div className={'column-times__part'}>
                                                <img src={icoMoon} alt={'Night / Noc'} /> <span>Night / Noc</span>
                                            </div>
                                        </div>
                                        <div className={'column-times__hours'}>
                                            <div className={'column-times__hour'}><span>6:00</span></div>
                                            <div className={'column-times__hour'}><span>8:00</span></div>
                                            <div className={'column-times__hour'}><span>10:00</span></div>
                                            <div className={'column-times__hour'}><span>12:00</span></div>
                                            <div className={'column-times__hour'}><span>14:00</span></div>
                                            <div className={'column-times__hour'}><span>16:00</span></div>
                                            <div className={'column-times__hour'}><span>18:00</span></div>
                                            <div className={'column-times__hour'}><span>20:00</span></div>
                                            <div className={'column-times__hour'}><span>22:00</span></div>
                                            <div className={'column-times__hour'}><span>0:00</span></div>
                                            <div className={'column-times__hour'}><span>2:00</span></div>
                                            <div className={'column-times__hour'}><span>4:00</span></div>
                                            <div className={'column-times__hour'}><span>6:00</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'schedule-table__days'}>
                                {Object.keys(schedules.actualWeek).map(function(key, i){
                                    let row = schedules.actualWeek[key];
                                    return <div className={'schedule-table__row'} key={key}>
                                        <div className={'schedule-table__row-date'}><span>{row.date}</span></div>
                                        <div className={'schedule-table__row-day'}><span>{row.day}</span></div>
                                        <div className={'schedule-table__row-shifts'}>
                                            {row.shifts.map(function(shift,i){
                                                let shift_color = shift.group.color;
                                                let meta_data = shift.meta_data;
                                                let description = '';

                                                Object.keys(meta_data).map(function(key, i) {
                                                    let meta_key = parseInt(key);

                                                    if(Number.isInteger(meta_key) && meta_data[key]) {
                                                        if(description !== '') {
                                                            description += ' | ';
                                                        }
                                                        description += meta_data[key];
                                                    }
                                                });

                                                return <div className={'schedule-table__shift'} key={shift.id} style={{gridColumnStart: shift.startPosition, gridColumnEnd: shift.endPosition, background: colors.hexToRGB(shift_color, 0.1)}}>
                                                    <div className={'schedule-table__shift-header'}>
                                                        <div className={'schedule-table__shift-time'} style={{background: colors.hexToRGB(shift_color)}}><span>{shift.time}</span></div>
                                                        <div className={'schedule-table__shift-title'}><span>{shift.group.title}</span></div>
                                                    </div>
                                                    <div className={'schedule-table__shift-description'}>
                                                        <p>{description}</p>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                        <div className={'schedule-table'}>
                            <div className={'schedule-table__header'}>
                                <div className={'schedule-table__header-week'}>
                                    <span className={'week-label'}>Následující týden</span>
                                    <span className={'week-info'}>{nextWeekYear} / {dates.nextWeek}. týden</span>
                                </div>
                                <div className={'schedule-table__header-columns'}>
                                    <div className={'column-date'}><span>Date / datum</span></div>
                                    <div className={'column-day'}><span>Day / den</span></div>
                                    <div className={'column-times'}>
                                        <div className={'column-times__parts'}>
                                            <div className={'column-times__part'}>
                                                <img src={icoSun} alt={'Morning / Ráno'} /> <span>Morning / Ráno</span>
                                            </div>
                                            <div className={'column-times__part'}>
                                                <img src={icoAfternoon} alt={'Afternoon / Odpoledne'} /> <span>Afternoon / Odpoledne</span>
                                            </div>
                                            <div className={'column-times__part'}>
                                                <img src={icoMoon} alt={'Night / Noc'} /> <span>Night / Noc</span>
                                            </div>
                                        </div>
                                        <div className={'column-times__hours'}>
                                            <div className={'column-times__hour'}><span>6:00</span></div>
                                            <div className={'column-times__hour'}><span>8:00</span></div>
                                            <div className={'column-times__hour'}><span>10:00</span></div>
                                            <div className={'column-times__hour'}><span>12:00</span></div>
                                            <div className={'column-times__hour'}><span>14:00</span></div>
                                            <div className={'column-times__hour'}><span>16:00</span></div>
                                            <div className={'column-times__hour'}><span>18:00</span></div>
                                            <div className={'column-times__hour'}><span>20:00</span></div>
                                            <div className={'column-times__hour'}><span>22:00</span></div>
                                            <div className={'column-times__hour'}><span>0:00</span></div>
                                            <div className={'column-times__hour'}><span>2:00</span></div>
                                            <div className={'column-times__hour'}><span>4:00</span></div>
                                            <div className={'column-times__hour'}><span>6:00</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'schedule-table__days'}>
                                {Object.keys(schedules.nextWeek).map(function(key, i){
                                    let row = schedules.nextWeek[key];
                                    return <div className={'schedule-table__row'} key={key}>
                                        <div className={'schedule-table__row-date'}><span>{row.date}</span></div>
                                        <div className={'schedule-table__row-day'}><span>{row.day}</span></div>
                                        <div className={'schedule-table__row-shifts'}>
                                            {row.shifts.map(function(shift,i){
                                                let shift_color = shift.group.color;
                                                let meta_data = shift.meta_data;
                                                let description = '';

                                                Object.keys(meta_data).map(function(key, i) {
                                                    let meta_key = parseInt(key);

                                                    if(Number.isInteger(meta_key) && meta_data[key]) {
                                                        if(description !== '') {
                                                            description += ' | ';
                                                        }
                                                        description += meta_data[key];
                                                    }
                                                });

                                                return <div className={'schedule-table__shift'} key={shift.id} style={{gridColumnStart: shift.startPosition, gridColumnEnd: shift.endPosition, background: colors.hexToRGB(shift_color, 0.1)}}>
                                                    <div className={'schedule-table__shift-header'}>
                                                        <div className={'schedule-table__shift-time'} style={{background: colors.hexToRGB(shift_color)}}><span>{shift.time}</span></div>
                                                        <div className={'schedule-table__shift-title'}><span>{shift.group.title}</span></div>
                                                    </div>
                                                    <div className={'schedule-table__shift-description'}>
                                                        <p>{description}</p>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                        <div className={'schedule-footer'}>
                            <div className={'schedule-balance-period'}>
                                {department_info.balance_period &&
                                    <button type={'button'} onClick={() => this.setState({ isBalancePeriodVisible: true })}>Vyrovnávací období / Balance period</button>
                                }
                            </div>
                            <div className={'schedule-legends'}>
                                <span className={'schedule-legends__label'}>Legenda</span>
                                {Object.keys(groups).map(function(key, i){
                                    let group = groups[key];
                                    let styles = {background: group.color}
                                    return <span key={'shift-group-'+i} className={'schedule-legends__shift-title'} style={styles}>{group.title}</span>
                                })}
                            </div>
                        </div>
                    </div>
                    {this.state.isBalancePeriodVisible &&
                        <Lightbox mainSrc={department_info.balance_period} onCloseRequest={() => this.setState({isBalancePeriodVisible: false})} />
                    }
                </div>
                )
        } else {
            return <div className={'empty-section'}>
                <div className={'empty-section__content'}>
                    <p>{i18next.t('Načítám...')}</p>
                </div>
            </div>;
        }
    }
}

export default (props) => (
    <SchedulesPage
        {...props}
        params={useParams()}
    />
);
