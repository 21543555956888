/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 15.03.22
 */

import flagCs from '../assets/images/flags/cs.png';
import flagEn from '../assets/images/flags/en.png';
import flagRo from '../assets/images/flags/ro.png';
import flagMn from '../assets/images/flags/mn.png';
import flagPl from '../assets/images/flags/pl.png';
import flagBg from '../assets/images/flags/bg.png';
import flagRu from '../assets/images/flags/ru.png';
import flagUk from '../assets/images/flags/uk.png';
import flagVi from '../assets/images/flags/vi.png';

export default {
    cs: {url: flagCs},
    en: {url: flagEn},
    mn: {url: flagMn},
    uk: {url: flagUk},
    ro: {url: flagRo},
    pl: {url: flagPl},
    bg: {url: flagBg},
    ru: {url: flagRu},
    vi: {url: flagVi}
}
