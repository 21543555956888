/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 09.12.21
 */
import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {EffectCube} from "swiper";
import {axiosApiInstance} from '../api/axiosApi';
import {getApiEndpoint} from '../api/api-helper';
import 'swiper/css';
import "swiper/css/effect-cube";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Link} from 'react-router-dom';
import i18next from 'i18next';
import localStorageService from '../localStorageService';

class PostsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            posts: [],
            isLoaded: false
        }
    }

    componentDidMount() {
        let {category, lang} = this.props.match.params;

        axiosApiInstance.get(getApiEndpoint('getCategoryPosts', {lang: lang, category: category}))
            .then((response) => {
                if(response.status === 200) {
                    let posts = response.data.data.posters;
                    this.setState({posts: posts, isLoaded: true});
                    localStorageService.setModes(response.data.data.modes);
                }
            }).catch((error) => {
            console.log(error);
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.match.params.category !== this.props.match.params.category) {
            let {category, lang} = this.props.match.params;
            this.setState({isLoaded: false});

            axiosApiInstance.get(getApiEndpoint('getCategoryPosts', {lang: lang, category: category}))
                .then((response) => {
                    if(response.status === 200) {
                        let posts = response.data.data.posters;
                        this.setState({posts: posts, isLoaded: true});
                    }
                }).catch((error) => {
                console.log(error);
            })
        }
    }

    render() {
        let {posts, isLoaded} = this.state;
        let {lang} = this.props.match.params;
        let slides = [];

        posts.forEach((post) => {
            if(post.image_url) {
                slides.push(
                        <SwiperSlide key={post.id}>
                            <img src={post.image_url} alt={''} style={{maxWidth:'100%', height:'auto'}} />
                        </SwiperSlide>
                );
            }
        });

        if(isLoaded) {
            if(slides.length > 0) {
                return <Swiper
                    effect={"cube"}
                    grabCursor={true}
                    cubeEffect={{
                        shadow: false,
                        slideShadows: false,
                        shadowOffset: 20,
                        shadowScale: 0.94
                    }}
                    pagination={false}
                    loop={true}
                    modules={[EffectCube]}
                    className="mySwiper"
                >
                    {slides}
                </Swiper>
            }else{
                return <div className={'empty-section'}>
                    <div className={'empty-section__content'}>
                        <p>{i18next.t('Momentálně není k dispozici žádný obsah.')}</p>
                        <Link to={{pathname: '/'+lang}}>{i18next.t('Hlavní nabídka')}</Link>
                    </div>
                </div>;
            }

        }else{
            return <></>;
        }

    }
}

export default PostsPage;
